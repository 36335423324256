import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { remarkForm } from "gatsby-tinacms-remark"
// const PageForm = {
//   fields: [
//     {
//       label: "Title",
//       name: "frontmatter.title",
//       description: "Enter the title of the content here",
//       component: "text",
//     },
//     {
//       label: "Description",
//       name: "frontmatter.description",
//       description: "Enter the content description",
//       component: "textarea",
//     },
//     {
//       label: "Excerpt",
//       name: "frontmatter.excerpt",
//       description: "Enter the content slogan",
//       component: "textarea",
//     },
//     {
//       label: "HTML Excerpt",
//       name: "frontmatter.custom_excerpt",
//       description: "Enter the cool HTML",
//       component: "textarea",
//     },
//     {
//       label: "Slug",
//       name: "frontmatter.slug",
//       description: "Enter content's slug",
//       component: "text",
//     },
//     {
//       label: "URL",
//       name: "frontmatter.url",
//       description: "Enter content's url",
//       component: "text",
//     },
//     {
//       label: "Body",
//       name: "frontmatter.custom_excerpt",
//       description: "Could be used but needs consideration",
//       component: "textarea",
//     },
//   ],
// }
const PageTemplate = ({ data, location }) => {
  const page = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.description || page.excerpt}
      />
      <section
        itemScope
        itemType="https://schema.org/Article"
      >
        <section
          dangerouslySetInnerHTML={{ __html: page.html }}
          itemProp="articleBody"
        />
      </section>
    </Layout>
  )
}

// export default remarkForm(PageTemplate, PageForm)
export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
      # ...TinaRemark
    }
    realizacje:allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: {frontmatter: {slug: {eq: "realizacja"}}}
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            slug
            description
            id
            className
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
